<!-- 客户服务器资源 -->
<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span class="text-base">服务器资源</span>
    </div>
    <el-form
        ref="form"
        :model="customerNodeForm"
        label-width="160px"
        label-position="left"
        size="small"
        :rules="rules"
        class="overflow-auto"
        style="height: calc(100vh - 300px)"
    >
      <el-form-item label="部署方式" prop="deployType">
        <el-radio-group
            v-model="customerNodeForm.deployType"
            @change="deployTypeChange"
        >
          <el-radio-button v-if="checkDeployType(1)" label="1"
          >SASS
          </el-radio-button>
          <el-radio-button v-if="checkDeployType(2)" label="2"
          >云独立部署
          </el-radio-button>
          <el-radio-button v-if="checkDeployType(3)" label="3"
          >离线独立部署
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
          label="域名类型"
          prop="domainType"
          v-if="customerNodeForm.deployType === '1'"
      >
        <el-radio-group v-model="customerNodeForm.domainType">
          <el-radio-button label="1">系统域名</el-radio-button>
          <el-tooltip
              class="item"
              effect="dark"
              content="后续版本支持"
              placement="top"
          >
            <el-radio-button label="2" disabled>用户自定义域名</el-radio-button>
          </el-tooltip>
        </el-radio-group>
        <div class="tips text-xs text-gray-400 mt-2">
          <i class="el-icon-info"></i>
          创建客户后，使用 https://{{ userInfo.saasUrl }} 地址进行访问。
        </div>
      </el-form-item>
      <template v-if="customerNodeForm.domainType === '2'">
        <el-form-item label="域名地址" prop="domainName">
          <el-input
              v-model="customerNodeForm.domainName"
              placeholder="请输入域名地址"
          >
            <template slot="prepend">https://</template>
          </el-input>
          <div class="tips text-xs text-gray-400 mt-2 leading-6">
            <div>
              1.如需填写客户自定义域名，请新增一条CNAME记录指向至
              {{ systemUrl }} 。
            </div>
            <div>
              2.请提前申请HTTPS证书（SSL证书），将证书公钥私钥填写至下方。
            </div>
            <div>
              3.目前需要技术人员接入操作，填写相关信息后，请联系客户经理。
            </div>
          </div>
        </el-form-item>
        <el-form-item label="SSL证书公钥" prop="publicKey">
          <el-input
              type="textarea"
              :rows="5"
              v-model="customerNodeForm.publicKey"
              placeholder="请输入SSL证书公钥"
          ></el-input>
        </el-form-item>
        <el-form-item label="SSL证书私钥" prop="privateKey">
          <el-input
              type="textarea"
              :rows="5"
              v-model="customerNodeForm.privateKey"
              placeholder="请输入SSL证书私钥"
          ></el-input>
        </el-form-item>
      </template>

      <el-form-item
          label="域名地址"
          prop="domainName"
          v-if="customerNodeForm.deployType === '2'"
      >
        <el-input
            v-model="customerNodeForm.domainName"
            placeholder="请输入域名地址"
        >
          <!--          <template slot="prepend">租户端访问地址</template>-->
        </el-input>
        <div class="tips text-xs text-gray-400 mt-2 leading-6">
          <div>1.需要先部署云独立租户端后，再进行以下操作。</div>
        </div>
      </el-form-item>

      <el-collapse
          v-model="activeNames"
          class="my-20"
          v-if="customerNodeForm.deployType !== '3'"
      >
        <el-collapse-item name="1">
          <div slot="title" class="tips text-xs my-5 font-bold">高级选项</div>
          <el-form-item label="是否调用租户端API">
            <el-switch
                v-model="isNotice"
                active-color="#13ce66"
                inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="租户uniqueId" prop="uniqueId" v-if="!isNotice">
            <el-input
                v-model="customerNodeForm.uniqueId"
                placeholder="请输入uniqueId"
            >
            </el-input>
            <div class="tips text-xs text-gray-400 mt-2 leading-6">
              <div>当无需调用创建租户服务时，需填写租户的uniqueId</div>
            </div>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </el-card>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      customerNodeForm: {},
      sysNodeList: [], // 服务器列表
      deployTypeTmp: null, // 临时存放部署方式类型
      rules: {
        deployType: [
          {
            required: true,
            message: '请选择部署方式',
            trigger: 'change'
          }
        ],
        domainName: [
          {
            required: true,
            message: '请输入域名地址',
            trigger: 'blur'
          }
        ],
        accessKeyId: [
          {
            required: true,
            message: '请输入accessKeyId',
            trigger: 'blur'
          }
        ],
        secretKey: [
          {
            required: true,
            message: '请输入secretKey',
            trigger: 'blur'
          }
        ]
      },
      isNotice: true,
      activeNames: ''
    }
  },
  components: {
    // computedItem
  },
  watch: {},
  computed: {
    ...mapGetters(['customerNode', 'userInfo'])
  },
  methods: {
    deployTypeChange (val) {
      this.$confirm('此操作将会清除部分填写的内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deployTypeTmp = val
        this.$store.commit('initCustomNode', {
          deployType: val,
          domainName: '',
          accessKeyId: this.customerNodeForm.accessKeyId,
          secretKey: this.customerNodeForm.secretKey
        })
        this.handlerCustomerNode()
        // this.getSysNode()
      }).catch(() => {
        this.customerNodeForm.deployType = this.deployTypeTmp
      })
    },
    handlerCustomerNode () {
      this.customerNodeForm = JSON.parse(JSON.stringify(this.customerNode))
      this.deployTypeTmp = this.customerNodeForm.deployType
    },
    checkDeployType (type) {
      switch (type) {
        case 1:
          return this.userInfo.saasSupport === 1
        case 2:
          return this.userInfo.cloudIndependentSupport === 1
        case 3:
          return this.userInfo.independentSupport === 1
      }
    },
    validateForm () {
      // console.log(this.customerNodeForm)
      return new Promise((resolve, reject) => {
        // 判断部署方式
        if (this.customerNodeForm.deployType === '1') {
          // sass模式
          if (this.customerNodeForm.domainType === '1') {
            this.customerNodeForm.domainName = this.userInfo.saasUrl
          }
        } else if (this.customerNodeForm.deployType === '2') {
          // 云独立部署模式
        }
        this.customerNodeForm.isNotice = this.isNotice ? 1 : 2
        // 判断是否调用租户端API 没有调用的话需要填写租户uniqueId
        if ((this.customerNodeForm.deployType === '1' || this.customerNodeForm.deployType === '2') && (!this.isNotice) && (this.customerNodeForm.uniqueId === '')) {
          this.$message.error('请填写租户uniqueId')
          return false
        }

        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$store.commit('setCustomerNode', JSON.parse(JSON.stringify(this.customerNodeForm)))
            resolve()
          } else {
            this.$message.error('请检查服务器资源表单内容')
            reject(valid)
          }
        })
      })
    }
  },
  mounted () {
    this.handlerCustomerNode()
  }
}
</script>
