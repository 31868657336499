<!--  -->
<template>
  <el-card class="box-card">
    <!--    {{ customerContainerForm }}-->
    <div slot="header" class="clearfix select-none">
      <span class="text-base">存储容器</span>
      <span class="tips text-xs text-gray-500 ml-3">
        建议选择与所选服务器相同的云厂商与存储区域
      </span>
    </div>
    <el-form
        ref="form"
        :model="customerContainerForm"
        label-width="160px"
        label-position="left"
        size="small"
        :rules="rules"
        class="overflow-auto"
    >
      <!--      style="height: calc(100vh - 500px)"-->
      <el-form-item label="云厂商" prop="factoryKey">
        <el-select
            v-model="customerContainerForm.factoryKey"
            placeholder="请选择"
            clearable
            @change="cloudTypeChange"
        >
          <el-option
              v-for="item in cloudTypeList"
              :key="item.factoryKey"
              :label="item.factoryName"
              :value="item.factoryKey"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="存储容器" prop="sysContainerId">
        <el-select
            v-model="customerContainerForm.sysContainerId"
            placeholder="请选择"
            clearable
            @change="sysContainerIdOnChange"
        >
          <el-option
              v-for="item in bucketList"
              :key="item.id"
              :label="item.containerName"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <span
            class="ml-3 text-gray-400 text-blue-900 cursor-pointer select-none hover:underline"
            @click="createBucket"
        >新建存储容器</span
        >
      </el-form-item>
      <div
          v-if="customerContainerForm.sysContainerId && curBucket"
          class="data bg-blue-50 w-72 h-auto border-blue-100 border-dotted border-2 rounded-md p-3 flex flex-col cursor-pointer"
      >
        <span class="text-base font-sans">
          <i class="el-icon-coin text-blue-800"></i>
          {{ curBucket.containerName || '' }}
        </span>
        <p class="text-xs text-gray-500 mt-2">
          存储区域
          <span class="text-gray-700 ml-1">{{
              curBucket.cloudFactoryAreaName
            }}</span>
        </p>
        <p class="text-xs text-gray-500 mt-1">
          容器大小
          <span class="text-gray-900 ml-1"
          >{{ curBucket.totalContainerSize }}GB</span
          >
        </p>
        <p class="text-xs text-gray-500 mt-1">
          生命周期
          <span class="text-gray-900 ml-1">{{ curBucket.periodType }}天</span>
        </p>
      </div>
    </el-form>
    <addBucketDialog
        ref="addBucketDialog"
        @on-add="onAddBucket"
    ></addBucketDialog>
  </el-card>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import addBucketDialog from '@/components/bucket/addBucketDialogV2'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      customerContainerForm: {},
      cloudTypeList: [],
      bucketList: [],
      curBucket: {},
      rules: {
        factoryKey: [
          {
            required: true,
            message: '请选择云厂商',
            trigger: 'change'
          }
        ],
        sysContainerId: [
          {
            required: true,
            message: '请选择存储容器',
            trigger: 'change'
          }
        ]
      }
    }
  },
  components: {
    addBucketDialog
  },
  computed: {
    ...mapGetters(['customerContainer', 'periodType'])
  },
  methods: {
    createBucket () {
      this.$refs.addBucketDialog.show()
    },
    getSysContainer () {
      this.$api.findAll('sysContainer', {
        factoryKey: this.customerContainerForm.factoryKey,
        useState: 1
      }).then(res => {
        res.data.forEach(item => {
          item.sysContainerId = item.id
        })
        this.bucketList = res.data
      })
    },
    cloudTypeChange () {
      this.customerContainerForm.sysContainerId = ''
      this.getSysContainer()
    },
    sysContainerIdOnChange () {
      this.handlerCurBucket()
    },
    handlerCustomerContainer () {
      this.customerContainerForm = JSON.parse(JSON.stringify(this.customerContainer))
    },
    onAddBucket (form) {
      this.getSysContainer()
      this.customerContainerForm = form
      this.handlerCurBucket()
    },
    handlerCurBucket () {
      this.curBucket = this.bucketList.find(item => item.sysContainerId === this.customerContainerForm.sysContainerId)
      this.curBucket.periodType = this.periodType.find(item => item.type === this.curBucket.periodType).label
    },
    validateForm () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$store.commit('setCustomerContainer', this.customerContainerForm)
            resolve()
          } else {
            this.$message.error('请选择存储容器')
            this.$emit('changeActive', '3')
            reject(valid)
          }
        })
      })
    },
    getBucket () {
      return this.curBucket
    },
    getEndpointList () {
      this.$api.findAll('endpoint').then(res => {
        this.cloudTypeList = res.data
      })
    }
  },
  mounted () {
    this.getEndpointList()
    this.handlerCustomerContainer()
  }
}
</script>
