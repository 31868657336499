<!-- 新增Secret  -->
<template>
  <el-dialog
      :append-to-body="true"
      title="新建密钥"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
  >
    <el-form
        ref="form"
        :model="form"
        label-width="160px"
        label-position="left"
        size="small"
        class="overflow-auto"
        :rules="rules"
    >
      <el-form-item label="云厂商" class="w-full" prop="factoryKey">
        <el-radio-group v-model="form.factoryKey" size="medium">
          <el-radio-button
              v-for="item in cloudFactoryList"
              :key="item.factoryKey"
              :label="item.factoryKey"
              :value="item.factoryKey"
          >{{ item.factoryName }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="密钥名称" class="w-full" prop="secretKeyName">
        <el-input
            v-model="form.secretKeyName"
            placeholder="请输入密钥名称"
            class="w-5/12"
        ></el-input>
      </el-form-item>
      <el-form-item label="AccessKeyId" class="w-full" prop="accessKeyId">
        <el-input
            v-model="form.accessKeyId"
            placeholder="请输入AccessKeyId"
            class="w-5/12"
        ></el-input>
      </el-form-item>
      <el-form-item
          label="AccessSecretKey"
          class="w-full"
          prop="accessSecretKey"
      >
        <el-input
            v-model="form.accessSecretKey"
            placeholder="请输入AccessSecretKey"
            class="w-5/12"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="tips text-xs text-gray-400 mt-2">
      系统无法检测填写的密钥内容是否正确，请按产品指导手册进行填写。
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogTableVisible = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      dialogTableVisible: false,
      form: {
        factoryKey: '',
        secretKeyName: '',
        accessKeyId: '',
        accessSecretKey: ''
      },
      cloudFactoryList: [],
      rules: {
        factoryKey: [{
          required: true,
          message: '请选择云厂商',
          trigger: 'change'
        }],
        secretKeyName: [{
          required: true,
          message: '请输入密钥名称',
          trigger: 'change'
        }],
        accessKeyId: [{
          required: true,
          message: '请输入accessKeyId',
          trigger: 'change'
        }],
        accessSecretKey: [{
          required: true,
          message: '请输入accessSecretKey',
          trigger: 'change'
        }]
      }
    }
  },
  methods: {
    show () {
      this.dialogTableVisible = true
    },
    getCloudFactory () {
      this.$api.findAll('endpoint').then(res => {
        this.cloudFactoryList = res.data
      })
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$api.save('sysSecretKey', this.form).then(res => {
            this.dialogTableVisible = false
            this.$message.success('添加成功')
            this.$emit('on-submit')
          })
        }
      })
    }
  },
  mounted () {
    this.getCloudFactory()
  }
}
</script>
