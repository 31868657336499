<!-- 创建容器 -->
<template>
  <el-dialog
      :append-to-body="true"
      title="创建容器"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
  >
    <el-form
        ref="form"
        :model="form"
        label-width="160px"
        label-position="left"
        size="small"
        class="overflow-auto"
        :rules="rules"
    >
      <el-form-item label="云厂商" class="w-full" prop="factoryKey">
        <el-radio-group
            v-model="form.factoryKey"
            size="medium"
            @change="factoryKeyOnChange"
        >
          <el-radio-button
              v-for="item in cloudFactoryList"
              :key="item.factoryKey"
              :label="item.factoryKey"
              :value="item.factoryKey"
          >{{ item.factoryName }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item
          label="存储产品"
          class="w-full"
          v-if="form.factoryKey"
          prop="productKey"
      >
        <el-select
            v-model="form.productKey"
            placeholder="请选择"
            clearable
            class="w-5/12"
            @change="productKeyOnChange"
        >
          <el-option
              v-for="item in yunProductList"
              :key="item.productKey"
              :label="item.productName"
              :value="item.productKey"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
          label="存储区域"
          class="w-full"
          v-if="form.productKey && form.productKey !== 'pk_10'"
          prop="endpointKey"
      >
        <el-select
            v-model="form.endpointKey"
            placeholder="请选择"
            clearable
            class="w-5/12"
        >
          <el-option
              v-for="item in yunEndpointList"
              :key="item.endpointKey"
              :label="item.endpointName"
              :value="item.endpointKey"
          >
          </el-option>
        </el-select>
        <div class="tips text-xs text-gray-400 mt-2">
          如列表中未找到所需的存储区域，请联系客户经理进行添加。
        </div>
      </el-form-item>

      <el-form-item
          label="endpoint地址"
          class="w-full"
          v-if="form.productKey && form.productKey === 'pk_10'"
          prop="endpoint"
      >
        <el-input
            class="w-5/12"
            v-model="form.endpoint"
            placeholder="请填写Endpoint地址"
        ></el-input>
      </el-form-item>

      <el-form-item label="密钥" class="w-full" prop="sysSecretKeyId">
        <el-select
            v-model="form.sysSecretKeyId"
            placeholder="请选择"
            clearable
            class="w-5/12"
        >
          <el-option
              v-for="item in sysSecretKeyList"
              :key="item.id"
              :label="item.secretKeyName"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <span
            class="ml-3 text-gray-400 text-blue-900 cursor-pointer select-none hover:underline"
            @click="createSecret"
        >新建密钥</span
        >
      </el-form-item>
      <el-form-item label="容器名称" class="w-full" prop="containerName">
        <el-input
            v-model="form.containerName"
            placeholder="请输入内容"
            class="w-5/12"
        ></el-input>
      </el-form-item>
      <el-form-item label="BucketName" class="w-full" prop="bucketName">
        <el-input
            v-model="form.bucketName"
            placeholder="请输入内容"
            class="w-5/12"
        ></el-input>
        <el-tooltip class="ml-2 cursor-pointer" effect="dark" content="只允许输入小写字母数字和短横杠" placement="top">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
        <div class="tips text-xs text-gray-400 mt-2">
          BucketName是全网唯一性，为避免重名，建议加上客户标识。
        </div>
      </el-form-item>
      <!--      <el-form-item label="生命周期" class="w-full" prop="periodType">-->
      <!--        <el-select v-model="form.periodType" placeholder="请选择" clearable class="w-5/12">-->
      <!--          <el-option-->
      <!--              v-for="item in periodTypeList"-->
      <!--              :key="item.value"-->
      <!--              :label="item.label"-->
      <!--              :value="item.value">-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item label="容器大小" class="w-full" prop="totalContainerSize">
        <el-input
            v-model="form.totalContainerSize"
            placeholder="请输入内容"
            class="w-5/12"
        >
          <template slot="append">GB</template>
        </el-input>
      </el-form-item>

      <el-collapse v-model="activeNames" class="mt-10">
        <el-collapse-item name="1">
          <div slot="title" class="tips text-xs my-5 font-bold">高级选项</div>
          <el-form-item label="是否创建Bucket">
            <el-switch
                v-model="isAddBucket"
                active-color="#13ce66"
                inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogTableVisible = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
    <addSecretDialog
        @on-submit="onSubmitSecret"
        ref="addSecretDialog"
    ></addSecretDialog>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import addSecretDialog from '@/components/bucket/addSecretDialog'

export default {
  data () {
    const validateBucketName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入bucketName'))
      } else {
        if (/^[a-z0-9-]+$/.test(value)) {
          callback()
        } else {
          callback(new Error('只允许输入小写字母数字和短横杠'))
        }
      }
    }
    return {
      dialogTableVisible: false,
      form: {
        factoryKey: '',
        productKey: '',
        containerName: '',
        bucketName: '',
        periodType: null,
        totalContainerSize: 0,
        warnThreshold: 0,
        endpointKey: '',
        sysSecretKeyId: '',
        isAddBucket: 1,
        endpoint: '',
        storageType: ''
      },
      rules: {
        factoryKey: [
          {
            required: true,
            message: '请选择云厂商',
            trigger: 'change'
          }
        ],
        productKey: [
          {
            required: true,
            message: '请选择云产品',
            trigger: 'change'
          }
        ],
        sysSecretKeyId: [
          {
            required: true,
            message: '请选择密钥',
            trigger: 'change'
          }
        ],
        endpointKey: [
          {
            required: true,
            message: '请选择存储区域',
            trigger: 'change'
          }
        ],
        containerName: [
          {
            required: true,
            message: '请填写容器名称',
            trigger: 'blur'
          }
        ],
        bucketName: [
          {
            required: true,
            message: '请填写bucketName',
            trigger: 'blur'
          },
          {
            validator: validateBucketName,
            trigger: 'blur'
          }
        ],
        periodType: [
          {
            required: true,
            message: '请选择存储生命周期',
            trigger: 'change'
          }
        ],
        totalContainerSize: [
          {
            required: true,
            message: '请填写容器大小',
            trigger: 'blur'
          }
        ]
      },
      cloudFactoryList: [],
      sysSecretKeyList: [],
      periodTypeList: [
        {
          label: '永久',
          value: 1
        },
        {
          label: '30天',
          value: 2
        },
        {
          label: '180天',
          value: 3
        },
        {
          label: '1年',
          value: 4
        }
      ],
      activeNames: '',
      isAddBucket: true
    }
  },
  components: {
    addSecretDialog
  },
  computed: {
    yunProductList () {
      if (this.form.factoryKey) {
        return this.cloudFactoryList.find(item => item.factoryKey === this.form.factoryKey).yunProductList || []
      } else {
        return []
      }
    },
    yunEndpointList () {
      if (this.form.factoryKey && this.yunProductList.length > 0) {
        const find = this.yunProductList.find(item => item.productKey === this.form.productKey)
        if (find) {
          return find.yunEndpointList
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  methods: {
    initData () {
    },
    show () {
      this.dialogTableVisible = true
    },
    createSecret () {
      this.$refs.addSecretDialog.show()
    },
    // 获取云厂商
    getCloudFactory () {
      this.$api.findAll('endpoint').then(res => {
        this.cloudFactoryList = res.data
      })
    },
    factoryKeyOnChange (val) {
      this.form.productKey = ''
      this.form.endpointKey = ''
      this.form.sysSecretKeyId = ''
      this.getSysSecretKey(val)
    },
    productKeyOnChange () {
      this.form.endpointKey = ''
    },
    // 获取密钥
    getSysSecretKey (factoryKey) {
      this.$api.page('sysSecretKey', {
        size: 999,
        factoryKey
      }).then(res => {
        this.sysSecretKeyList = res.data.content
      })
    },
    submit () {
      // 回滚备份
      const temp = JSON.parse(JSON.stringify(this.form))
      this.$refs.form.validate(valid => {
        if (this.form.productKey === 'pk_10') {
          this.form.storageType = '10'
        }
        if (valid) {
          if (this.form.factoryKey === 5) {
            this.form.endpointKey = ''
            this.form.productKey = ''
          }
          const loading = this.$loading({
            lock: true,
            text: '创建中...',
            spinner: 'el-icon-loading'
          })
          this.form.isAddBucket = this.isAddBucket ? 1 : 2
          this.$api.save('sysContainer', this.form).then((res) => {
            this.form.sysContainerId = res.data
            this.$emit('on-add', this.form)
            this.$message.success('添加成功')
            this.dialogTableVisible = false
          }).catch(e => {
            this.form.endpointKey = temp.endpointKey
            this.form.productKey = temp.productKey
          }).finally(() => {
            loading.close()
          })
        }
      })
    },
    onSubmitSecret () {
      this.getSysSecretKey(this.form.factoryKey)
    }
  },
  mounted () {
    this.getCloudFactory()
  }
}
</script>
