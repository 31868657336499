<!-- 创建客户 -->
<template>
  <div>
    <blockTitle title="添加租户">
      <template slot="customContent">
        <el-button
            size="small"
            class="ml-16 bg-gray-400 text-gray-50"
            @click="cancel"
        >取消
        </el-button>
        <el-button
            size="small"
            long
            class="ml-4 bg-blue-900 text-gray-50 w-28"
            @click="submit"
        >
          保存提交
        </el-button>
      </template>
    </blockTitle>
    <el-tabs v-model="activeName" type="card" :before-leave="tabBeforeLeave">
      <el-tab-pane label="服务器资源" name="1">
        <serverInfo ref="serverInfo"></serverInfo>
      </el-tab-pane>
      <el-tab-pane label="客户基本信息" name="2">
        <customerInfo
            ref="customerInfo"
            @changeActive="changeActive"
        ></customerInfo>
      </el-tab-pane>
      <el-tab-pane
          label="存储容器"
          name="3"
          v-if="customerNode.deployType !== '3'"
      >
        <customerContainer
            ref="customerContainer"
            @changeActive="changeActive"
        ></customerContainer>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<style>
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active,
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #fff3e6;
  color: #ff8807;
  border-bottom-color: #ff8807;
  border-bottom-width: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover,
.el-tabs__item:hover {
  color: #ff8807;
}
</style>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import serverInfo from '@/components/client/serverInfo'
import customerInfo from '@/components/client/customerInfo'
import customerContainer from '@/components/client/customerContainerV2'
import { mapGetters } from 'vuex'

export default {
  name: 'createClient',
  data () {
    return {
      activeName: '1'
    }
  },
  components: {
    serverInfo,
    customerInfo,
    customerContainer
    // customerDeviceList
    // customerPanel
  },
  computed: {
    ...mapGetters(['customerNode', 'customerInfo', 'customerContainer', 'customerDeviceList', 'customerPanel'])
  },
  watch: {},
  methods: {
    async tabBeforeLeave () {
      switch (this.activeName) {
        case '1':
          if (await this.$refs.serverInfo.validateForm()) {
            return true
          }
          break
        default:
          return false
      }
    },
    async submit () {
      if (!await this.checkForm()) {
        return false
      }
      const loading = this.$loading({
        lock: true,
        text: '正在创建租户，请稍等...',
        spinner: 'el-icon-loading'
      })
      this.$api.save('customerInfo', {
        customerNode: this.customerNode,
        customerInfo: this.customerInfo,
        customerContainer: this.customerContainer
      }).then(() => {
        this.clearAllForm()
        this.$message.success('保存成功')
        this.$router.push({ path: '/clientList' })
      }).finally(() => {
        loading.close()
      })
    },
    async checkForm () {
      try {
        await this.$refs.serverInfo.validateForm()
        await this.$refs.customerInfo.validateForm()
        if (this.customerNode.deployType !== '3') {
          // 离线独立部署不需要存储容器
          await this.$refs.customerContainer.validateForm()
        }
        // await this.$refs.customerPanel.validateForm()
        return true
      } catch (e) {
        // this.$message.error('请检查表单内容')
        return false
      }
    },
    cancel () {
      this.clearAllForm()
      this.$router.push({ path: '/clientList' })
    },
    clearAllForm () {
      this.$store.commit('clearCustomNode')
      this.$store.commit('clearCustomerInfo')
      this.$store.commit('clearCustomerContainer')
      this.$refs.serverInfo.handlerCustomerNode()
      this.$refs.customerInfo.handlerCustomerInfo()
      this.$refs.customerContainer.handlerCustomerContainer()
    },
    changeActive (activeName) {
      this.activeName = activeName
    }
  },
  mounted () {
  }
}
</script>
